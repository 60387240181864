.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn 1s 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate-hover {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;
  animation: rubberBand 1s;

  &:hover {
      animation: none;
      color: #ffd700;
  }
}

@for $i from 1 through 38 {
  .text-animate._#{$i} {
      animation-delay: #{$i * 0.1}s;
  }
}