.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.about-page,
.container.contact-page,
.container.portfolio-page,
.container.timeline-page,
.container.skills-page {

  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: 10px;

    &::before {
      content: '<h1>';
      font-family: "La Belle Aurore";
      font-size: 20px;
      font-weight: 100;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.5;
      line-height: 18px;
    }

    &::after {
      content: '<h1/>';
      font-family: 'La Belle Aurore';
      font-size: 20px;
      font-weight: 100;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  p {
    font-size: 17px;
    color: white;
    font-family: sans-serif;
    font-weight: 300;
    min-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-area {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    p span {
      color:#ffd700;
    }
  }

  .text-animate-hover {
    &:hover {
      color: white
    }
  }
}


@function multiple-box-shadow($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';

  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }

  @return unquote($value);
}


$shadows-small: multiple-box-shadow(1400);
$shadows-medium: multiple-box-shadow(400);
$shadows-big: multiple-box-shadow(200);

.stars {
  width: 1px;
  height: 1px;

  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;

  &:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

.stars2 {
  width: 2px;
  height: 2px;

  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

.stars3 {
  width: 3px;
  height: 3px;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

@keyframes animStar {
  from {
    transform: translateY(100px);
  }

  to {
    transform: translateY(-2000px);
  }
}

.footer {
  position: fixed;
  right: 45px; bottom: 0px;
  animation: pulse 2s infinite;

  a {
    font-size: 30px;

    &:hover svg {
      color:#ffd700;
      animation: jello 1s;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }

  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.about-page,
    &.contact-page,
    &.portfolio-page,
    &.timeline-page,
    &.skills-page {
      .text-area {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }

    &.portfolio-page {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      h1.page-title {
        margin-left: 20px;
        margin-top: 20px;
      }

      .image {
        height: 200px;
        max-width: calc(50% - 10px);
      }
    }
  }

  .tagcloud-wrap {
    position: relative;
    transform: scale(0.334);
    bottom: 320px;
    left: 400px;

    .tagcloud {
      color: #ffd700;
      font-weight: 800;
      font-size: 40px;
    }
  }
}