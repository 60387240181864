.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .plus-btn {
        float: right;
        transform: scale(0.6);
        cursor: pointer;
    }

    .plus-btn:hover {
        color: #ffd700;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);
        object-fit: fill;

        .project-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.2) 0,
                    rgba(0, 0, 0, 1));
            bottom: -70px
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            margin-right: 21px;
            color: #fff;
            font-weight: 300;
            text-align: center;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            margin-left: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .demo-btn {
            margin-top: 30px;
            margin-bottom: 10px;
            margin-left: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #FF3131;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        .demo-btn:hover {
            transform: translateY(-3px);
            background: #FF3131;
        }

        &:after {
            content: '';
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

.video-modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .video-modal-content {
    position: relative;
    width: 50%;
    height: auto;
    max-width: 90vw;
    background-color: grey transparent;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.close {
    position: absolute;
    top: -6px;
    right: 0px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color:#ffd700;
    cursor: pointer;
}

.video-js {
    max-height: 60vh;
}

@media screen and (max-width: 1200px) {
    .container.portfolio-page {
        .images-container {
            display: flex;

            .image {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                padding: 40px;

                .description {
                    display: none;
                }

                .title {
                    font-size: 10px;
                    display: block;
                    transform: initial;
                }

                .btn {
                    border: 2px solid #ffd700;
                    width: 50px;
                    height: 25px;
                    padding: 0 5px;
                    font-size: 10px;
                }

                .demo-btn {
                    border: 2px solid #FF3131;
                    width: 50px;
                    height: 25px;
                    padding: 0 5px;
                    font-size: 10px;
                }
            }
        }
    }
}